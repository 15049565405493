import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: "/setEvent",
    name: "SetEvent",
    component: () => import('../views/SetEvent.vue')
  },
  {
    path: "/selectActive",
    name: "SelectActive",
    component: () => import('../views/SelectActive.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: "history",
})

export default router
